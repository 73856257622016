import React, { useState, useEffect } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import { toast } from "react-toastify";

const OrderHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const orderHistoryList = useSelector((state) => state?.order?.ordersList);
    console.log('orderHistoryList: ', orderHistoryList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "allorder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message", });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Order History| KeTA Visa Portal";
    }, [dispatch, navigate, page, limit]);

    const tableData = orderHistoryList;

    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1>Order History</h1>
            </StyledPageTitle>
            <form>
                <StyledOrderBlock>
                    <div className="table-block table-responsive">
                        <ReactDataTable
                            data={tableData}
                            setPage={setPage}
                            setLimit={setLimit}
                            orderName="allorder"
                        />
                    </div>
                </StyledOrderBlock>
            </form>
        </StyledContainer>
    );
};

export default OrderHistory;
