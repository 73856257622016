import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "../../services/OrderService";

export const getFolderCount = createAsyncThunk("/order/getFolderCount", async () => {
    try {
        const response = await OrderService.getFolderCount();
        const data = await response?.data;
        return data;
    } catch (error) {
        return error?.response?.data;
    }
});

export const getOrdersList = createAsyncThunk("/order/getOrdersList", async(orderData) => {
    try {
        const response = await OrderService.getOrdersList({ orderData });
        const data = response?.data;
        return data;
    } catch(error) {
        return error?.response?.data;
    }
});

export const searchOrder = createAsyncThunk("/order/searchOrder", async (searchData) => {
    try {
        const response = await OrderService.searchOrder(searchData);
        const data = await response?.data;
        return data;
    } catch (error) {
       return error?.response?.data;
    }
});

export const deleteOrdersData = createAsyncThunk("/order/deleteOrdersData", async (data) => {
    try {
        await OrderService.deleteOrdersData(data);
        return data.orderId;
    } catch (error) {
       return error?.response?.data;
    }
});


export const sendRefundMail = createAsyncThunk("/admin/sendRefundMail", async (refundData) => {
    try {
        const response = await OrderService.sendRefundMail({
            orderId: refundData,
        });
        const data = await response?.data;
        return data;
    } catch (error) {
       return error?.response?.data;
    }
});

export const moveOrderStatus = createAsyncThunk("/order/moveOrderStatus", async (orderStatus) => {
    try {
        const response = await OrderService.moveOrderStatus(orderStatus);
        const data = await response.data;
        return data;
    } catch (error) {
        return error.response.data;
    }
});

export const getOrderDetailsByOrderId = createAsyncThunk("/order/getOrderDetailsByOrderId", async (orderId) => {
    try {
        const response = await OrderService.getOrderDetailsByOrderId(orderId);
        const data = await response.data;
        return data;
    } catch (error) {
        return error.response.data;
    }
});

export const uploadVisaDocument = createAsyncThunk("/admin/uploadVisaDocument",async (uploadData) => {
    try {
        const response = await OrderService.uploadVisaDocument(uploadData);
        const data = await response.data;
        return data;
    } catch (error) {
        return error.response.data;
    }
});

export const resendMail = createAsyncThunk("/admin/resend-mail", async (mailData) => {
    try {
        const response = await OrderService.resendMail(mailData);
        const data = await response.data;
        return data;
    } catch (error) {
        return error.response.data;
    }
});

export const orderSlice = createSlice({
    name: "order",
    initialState: {
        loading: false,
        error: null,
        mailCount: null,
        ordersList: [],
        orderDetails: [],
        processOrdersList: [],
        orderInfo: {},
        phoneNumberList: [],
        showSideBar: false,
        selectedData: [],
        count: {},
        orderId: null,
    },
    reducers: {
        toggleSidebar: (state) => ({
            ...state,
            showSideBar: !state.showSideBar,
        }),
        toggleNavbar: (state) => ({
            ...state,
            showNavbar: !state.showNavbar,
        }),
        handleChange: (state, action) => {
            const allDeleteData = action.payload.selectedRows;
            const data = allDeleteData.map(({ id, status: status }) => ({
            id,
            status,
            }));
            return {
            ...state,
            selectedData: data,
            };
        },
        cleanOrdersList: (state) => ({
            ordersList: [],
        }),
    },

    extraReducers: (builder) => {
        builder.addCase(getFolderCount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getFolderCount.fulfilled, (state, action) => {
            state.loading = false;
            state.count = action?.payload?.data;
        });
        builder.addCase(getFolderCount.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(getOrdersList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOrdersList.fulfilled, (state, action) => {
            state.loading = false;
            state.ordersList = action?.payload?.data;
        });
        builder.addCase(getOrdersList.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getOrderDetailsByOrderId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOrderDetailsByOrderId.fulfilled, (state, action) => {
            state.loading = false;
            state.orderDetails = action?.payload?.data;
        });
        builder.addCase(getOrderDetailsByOrderId.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(uploadVisaDocument.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(uploadVisaDocument.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(uploadVisaDocument.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(deleteOrdersData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteOrdersData.fulfilled, (state, action) => {
            state.loading = false;
            state.ordersList = state.ordersList.filter(
              (item) => action?.payload.indexOf(item.id) === -1
            );
        });
        builder.addCase(deleteOrdersData.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(searchOrder.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchOrder.fulfilled, (state, action) => {
            state.loading = false;
            state.ordersList = action?.payload?.data;
        });
        builder.addCase(searchOrder.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(sendRefundMail.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(sendRefundMail.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(sendRefundMail.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const { toggleSidebar, handleChange, handlePerRowsChange, handlePageChange, cleanOrdersList, toggleNavbar } = orderSlice.actions;
export default orderSlice.reducer;

