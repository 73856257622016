import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/user/authSlice";
import orderReducer from "../features/order/orderSlice";
import gateWayReducer from "../features/paymentgateway/gateWaySlice";
import remarkReducer from "../features/remark/remarkSlice";
import manageTeamReducer  from "../features/manageteam/manageTeamSlice";
import visitorReducer  from "../features/visitor/visitorSlice";

export default configureStore({
    reducer: {
        auth: authReducer,
        order: orderReducer,
        gateway: gateWayReducer,
        remark: remarkReducer,
        manageTeam: manageTeamReducer,
        vistor: visitorReducer
    }
});