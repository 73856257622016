import React from "react";
import { Route, Routes } from "react-router-dom";
import TrackOrder from '../../pages/TrackOrder/TrackOrder';
import DownloadHistory from '../../pages/DownloadHistory/DownloadHistory';

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/track-order/:orderId" element={<TrackOrder />} />
        <Route path="/get-new-order-details/:orderId" element={<DownloadHistory />} />
      </Routes>
    </main>
  );
};

export default Main;
